
import React, { Component } from 'react';
import './SliderGlobal.css';
import './SliderPodcasts.css';
import Slider from 'react-slick';
import { allEntrepreneurs } from "../../cms/entrepreneurs";
import { lodash } from 'lodash';
import { CubeSvg } from './CubeSvg'
import Popup from '../Popup';

export default class SliderEntrepreneurs extends Component {

    constructor(props) {
        super(props);
        this.state = { popupContent: "" };
    }
    
    randomEntrepreneursWithListen = function() {
        const filteredList = lodash.filter(allEntrepreneurs, 'listen'); //
        const randomizedList = lodash.shuffle(filteredList);
        return randomizedList;
    }

    getSvg(image) {
    return CubeSvg({image});
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slidesPerRow: 1,
            rows: 3,
            responsive: [
                {
                    breakpoint: 922,
                    settings: {
                        slidesPerRow: 1,
                        rows: 2,
                    },
                },
            ],            
        };
        return (
            <div id="Podcasts--Slider" className="Podcasts--Slider">
                <Slider {...settings}>
                    {this.randomEntrepreneursWithListen().map((element, index) =>  <div key={index} className={"Cube podcasts-entrepreneur" + index} 
                        onClick={event => {
                          event.preventDefault()
                          this.setState({popupContent: element.listen.split('.js?')[0] + '?client_source=small_player&iframe=true'}); //ensure small buzzsprout player
                          //window.open(element.listen.split('.js?')[0]);
                          document.getElementById("PodcastsContextualPopup").click();
                        }}>
                       <figure className="Image-Bg">
                            {/* <img src="/images/Slider/cube-full-black.png" /> */}
                            {this.getSvg(element.image)}
                        </figure>
                        <figure className="Side Top">
                            <p>{(element.name.includes('&')) ? element.name.split('&')[0] +"&" : element.name.split(' ')[0]} <br />
                            {(element.name.includes('&')) ? element.name.split('&')[1] : element.name.split(' ')[1]}<br/>
                                <span>{element.company}</span>
                            </p>
                        </figure>
                        {/* <figure className="Side Right">
                            <img src={element.image} />
                        </figure> */}
                    </div>
                    )}
                </Slider>
                 <div id="PopupContainer">
                    <Popup
                        popupContent={<iframe class="podcastPopup" title='podcast' width="100%" src={this.state.popupContent}></iframe>}
                    >
                        <div id="PodcastsContextualPopup"></div>
                    </Popup>
                </div>
            </div> 
        );
    }
}
