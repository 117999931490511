import React from 'react';

export const CubeSvg = props => {

    return <div className="svg--cube">
             <div className="desktop">
				<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 341 377.6">
					<defs>
						<clipPath id="clip-path">
							<path id="rightside" d="M321.88,277.76l-156.41,90.3V187.45l156.41-90.3" fill="none"/>
						</clipPath>
					</defs>
					<g id="Group_1931" data-name="Group 1931">
						<g id="clippedimage">
							<g clipPath="url(#clip-path)">
								<image id="entrepreneur" width="210" height="325" transform="translate(160.72 90.02) scale(0.86)" href={props.image} />
							</g>
						</g>
						<path id="rightside-overlap" d="M321.88,277.76l-156.41,90.3V187.45l156.41-90.3" className="st0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
						<path id="leftside" d="M9.06,277.76l156.41,90.3V187.45L9.06,97.15Z" className="st0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
						<path id="topcorner" d="M9.06,98.15,165.47,7.85l156.41,90.3" className="st0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
					</g>
					<circle id="bottomdot" cx="165.33" cy="369.28" r="8.33" className="st1"/>
					<circle id="topdot" cx="165.47" cy="8.33" r="8.33" className="st1"/>
					<circle id="bottomrightdot" cx="321.33" cy="277.76" r="8.33" className="st1"/>
					<circle id="toprightdot" cx="321.33" cy="98.6" r="8.33" className="st1"/>
					<circle id="topleftdot" cx="8.33" cy="99.37" r="8.33" className="st1"/>
					<circle id="middledot" cx="165.33" cy="188.22" r="8.33" className="st1"/>
					<line id="rightline" x1="321.88" y1="98.15" x2="321.88" y2="278.76" className="st0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
					<circle id="bottomleftdot" cx="8.33" cy="278.82" r="8.33" className="st1"/>
				</svg>

			</div>
         </div>
    ;
}
