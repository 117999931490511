import React, { Component } from 'react';
import './SliderArticles.css';
import './SliderGlobal.css';
import { allEntrepreneurs } from "../../cms/entrepreneurs";
import { lodash } from 'lodash';
import Slider from 'react-slick';
import { CubeSvg } from './CubeSvg'
//import Popup from '../Popup';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {urlWithUtms} from '../../utils/urlWithUtms'


export default class SliderEntrepreneurs extends Component {

    constructor(props) {
        super(props);
        this.state = { popupContent: "" };
    }
    
    randomEntrepreneursWithListen = function() {
        const filteredList = lodash.filter(allEntrepreneurs, 'read'); //
        const randomizedList = lodash.shuffle(filteredList);
        return randomizedList;
    }

    getSvg(image) {
        return CubeSvg({image});
    }


    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slidesPerRow: 1,
            rows: 3,
            responsive: [
                {
                    breakpoint: 922,
                    settings: {
                        slidesPerRow: 1,
                        rows: 2,
                    },
                },
            ],            
        };
        return (
            <div id="Articles--Slider" className="Articles--Slider">
                <Slider {...settings}>
                    {this.randomEntrepreneursWithListen().map((element, index) =>  <div key={index} className={"Cube articles-entrepreneur" + index} 
                        onClick={event => {
                          event.preventDefault()
                         // this.setState({popupContent: element.listen});
                         // document.getElementById("ContextualPopup").click();
                        
                         window.open(urlWithUtms(element.read));

                        }}>
                        <figure className="Image-Bg">
                            {this.getSvg(element.image)}
                        </figure>
                        <figure className="Side Top">
                            <p>{(element.name.includes('&')) ? element.name.split('&')[0] +"&" : element.name.split(' ')[0]} <br />
                            {(element.name.includes('&')) ? element.name.split('&')[1] : element.name.split(' ')[1]}<br/>
                                <span>{element.company}</span>
                            </p>
                        </figure>
                    </div>
                    )}
                </Slider>
            
                {/* <div id="PopupContainer">
                    <Popup
                        popupContent={<iframe width="100%" src={this.state.popupContent}></iframe>}
                    >
                        <div id="ContextualPopup"></div>
                    </Popup>
                </div> */}
            
            </div>

            
        );
    }    
}
