import React from 'react';
import { graphql } from 'gatsby';
import Content from '../components/Content';
import Layout from '../components/Layout';
import './HomePage.css';
//import WistiaVideo from '../components/WistiaVideo/WistiaVideo';
import SliderVideos from '../components/Slider/SliderVideos';
import SliderPodcasts from '../components/Slider/SliderPodcasts';
import SliderArticles from '../components/Slider/SliderArticles';

// Export Template for use in CMS preview
export const HomePageTemplate = ({
    body,
    title,
    h1,
    h1Big,
    h2,
    featuredImage,
    featuredImageMobile,
    YoutubeVideo,
    subtitle,
    vidoesIconTitle,
    videosHeader,
    videosParagraph,
    podcastsIconTitle,
    podcastsHeader,
    podcastsParagraph,
    articlesIconTitle,
    articlesHeader,
    articlesParagraph,
}) => (
    <main className="Home">
        <section className="section Home--TopHeader">
            <div className="Home--TopHeader-Background">
                <img className="Home--featuredDesktop" src={featuredImage} alt="Weave Connected" />
                <img className="Home--featuredMobile" src={featuredImageMobile} alt="Weave" />
            </div>
            <div className="container">
                <div className="PageHeader--Home ">
                        <span className="PageHeader--Home-Small">{h1}</span>
                    <h1 className="Home--TopHeader-Home-H1 gradient">
                        <Content source={h1Big} />
                    </h1>
                </div>
            </div>
        </section>
        <section className="section Home--Title">
            <hr className="Home--Hr" />
            <h2 className="Home--H2">
                <Content source={h2} />
            </h2>
        </section>
        <section className="section Home--Video">
            <div className="container">
                {/* <WistiaVideo
                    placeholderImage="https://fast.wistia.com/embed/medias/vjzszvgfsm/swatch"
                    wistiaId="vjzszvgfsm"
                /> */}
                <div className="Iframe">
                <Content source={YoutubeVideo} />
                </div>
            </div>
        </section>
        <section className="section Home--Slider">
            <div className="container">
                <div className="Grid--Container-2-Col-Right">   
                    <div className="Grid--Col">
                        <div>
                            <img src="/images/IconPlay.svg" alt="" />  
                            <Content
                                className="Home--IconTitle"
                                source={vidoesIconTitle}
                            />
                        </div>
                        <Content
                            className="Slider--Title"
                            source={videosHeader}
                        />
                        <Content className="Slider--Details" source={videosParagraph} />
                        <p className="Home--Click-Below"> Click entrepreneurs to view video
                        </p>
                    </div>
                    <div className="Grid--Col">
                        <SliderVideos />
                    </div>
                </div>
            </div>
        </section>
    <section className="section Home--Slider">
            <div className="container">
                <div className="Grid--Container-2-Col-Left Col-Order">
                    <div className="Grid--Col Order">
                        <SliderPodcasts />
                    </div>
                    <div className="Grid--Col Order">
                        <div>
                            <img src="/images/IconListen.svg" alt="" />
                            <Content
                                className="Home--IconTitle"
                                source={podcastsIconTitle}
                            />
                        </div>
                        <Content
                            className="Slider--Title"
                            source={podcastsHeader}
                        />
                        <Content className="Slider--Details" source={podcastsParagraph} />
                        <p className="Home--Click-Below-2">
                            Click entrepreneurs to listen to podcasts
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section Home--Slider">
            <div className="container">
                <div className="Grid--Container-2-Col-Right">
                    <div className="Grid--Col">
                        <div>
                            <img src="/images/IconRead.svg" alt="" />
                            <Content
                                className="Home--IconTitle"
                                source={articlesIconTitle}
                            />
                        </div>
                        <Content
                            className="Slider--Title"
                            source={articlesHeader}
                        />
                        <Content className="Slider--Details" source={articlesParagraph} />
                        <p className="Home--Click-Below">
                            Click entrepreneurs to read articles
                        </p>
                    </div>
                    <div className="Grid--Col">
                        <SliderArticles />
                    </div>
                </div>
            </div>
        </section>
    </main>
);

const HomePage = ({ data: { page } }) => (
    <Layout meta={page.frontmatter.meta || false}>
        <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
    </Layout>
);

export default HomePage;

export const pageQuery = graphql`
    ## Query for HomePage data
    ## Use GraphiQL interface (http://localhost:8000/___graphql)
    ## $id is processed via gatsby-node.js
    ## query name must be unique to this file
    query HomePage($id: String!) {
        page: markdownRemark(id: { eq: $id }) {
            ...Meta
            html
            frontmatter {
                title
                h1
                h1Big
                h2
                YoutubeVideo
                vidoesIconTitle
                videosHeader
                videosParagraph
                podcastsIconTitle
                podcastsHeader
                podcastsParagraph
                articlesIconTitle
                articlesHeader
                articlesParagraph
                featuredImage
                featuredImageMobile
            }
        }
    }
`;
